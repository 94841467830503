import { getLangParam, getLangQueryParam, guessLanguage } from '@/utils/lang';

export default {
  methods: {
    guessLanguage() {
      return guessLanguage(navigator, this.$route);
    },
    getLangParam() {
      return getLangParam(this.$route);
    },
    getLangQueryParam() {
      return getLangQueryParam(this.$route);
    },
    getSalesLink() {
      return this.guessLanguage() === 'hu'
        ? 'https://www.optimonk.hu/'
        : 'https://www.optimonk.com/';
    },
  },
};
