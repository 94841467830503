<template lang="pug">
.wrapper
  .container.py-5.mt-5
    #new-password-form.row
      .col-5.mx-auto(v-if="token && !invalidToken && user")
        form(@submit.prevent="changePassword()" autocomplete="off")
          template(v-if="!forgottenPassword")
            om-heading.mb-4(h3) {{ $t('new-user-password.welcome') }} {{ user.firstName }}!
            om-body-text(bt400md) {{ $t('new-user-password.startUsingText') }}
          template(v-else)
            om-heading.mb-4(h3) {{ $t('new-user-password.setNewPassword') }}
          .your-email
            div {{ $t('new-user-password.yourEmailIs') }}
            .email {{ user.email }}
          .form-group
            om-input#password.mb-1(
              large
              type="password"
              :placeholder="$t('new-user-password.passwordPlaceholder')"
              v-model.trim="password"
              :error="$v.password.$error"
            )
              template(slot="error" v-if="!$v.password.required && $v.password.$error")
                span {{ $t('requiredField') }}
              template(slot="error" v-else-if="!$v.password.minLength && $v.password.$error")
                span {{ $t('passwordLength', { length: $v.password.$params.minLength.min }) }}
          .form-group
            om-input#repeatPassword.mb-1(
              large
              type="password"
              :placeholder="$t('new-user-password.repeatPasswordPlaceholder')"
              v-model.trim="repeatPassword"
              :error="$v.repeatPassword.$error"
            )
              template(
                slot="error"
                v-if="!$v.repeatPassword.sameAsPassword && $v.repeatPassword.$error"
              )
                span {{ $t('new-user-password.identicalPasswords') }}
          om-button.mx-auto.px-5(primary type="submit") {{ $t('new-user-password.setPassword') }}
      .col-5.mx-auto(v-else-if="forgottenPassword && !token")
        form(@submit.prevent="resetPassword()")
          om-heading.mb-4(h3) {{ $t('new-user-password.forgotYourPassword') }}
          .your-email
            div {{ $t('new-user-password.enterYourEmail') }}
          .form-group
            om-input#email.mb-1(
              large
              type="text"
              :placeholder="$t('new-user-password.emailPlaceholder')"
              v-model.trim="email"
              :error="$v.email.$error"
            )
              template(slot="error" v-if="!$v.email.required && $v.email.$error")
                span {{ $t('new-user-password.emailRequired') }}
              template(slot="error" v-else-if="$v.email.$error && !$v.email.isCoolEmail")
                span {{ $t('validEmail') }}
          om-button.mx-auto.px-5(primary type="submit") {{ $t('new-user-password.sendResetLink') }}
          .form-group.row.justify-content-center(v-if="this.message.error")
            .text-danger.mt-3.font-size-0--9375 {{ this.message.error }}
          .form-group.row.justify-content-center(v-if="this.message.success")
            .text-success.mt-4.font-size-0--9375 {{ this.message.success }}
      .col-6.mx-auto.invalid-token(v-else)
        om-heading.mb-4(h1) {{ $t('new-user-password.invalidToken') }}
        om-button.mx-auto.px-5(primary @click="$router.push({ name: 'forgotten-password' })") {{ $t('new-user-password.askNewLink') }}
</template>

<script>
  import { mapMutations } from 'vuex';
  import moment from 'moment';
  import CHANGE_FORGOTTEN_PASSWORD from '@/graphql/ChangeForgottenPassword.gql';
  import RESET_PASSWORD from '@/graphql/ResetPassword.gql';
  import GET_USER_BY_PASSWORD_TOKEN from '@/graphql/GetUserByPasswordToken.gql';
  import { showInitialScreenByUserType } from '@/router/utils';
  import guessLang from '@/mixins/guessLang';

  import { minLength, required, sameAs, email } from 'vuelidate/lib/validators';

  export default {
    name: 'NewUserPassword',
    mixins: [guessLang],
    data() {
      return {
        forgottenPassword: null,
        invalidToken: false,
        user: null,
        token: null,
        email: '',
        password: '',
        repeatPassword: '',
        message: {
          error: '',
          success: '',
        },
      };
    },
    async created() {
      const {
        params: { token },
      } = this.$route;
      const { forgottenPassword } = this.$route.meta;
      let lang = this.guessLanguage();

      this.token = token;
      this.forgottenPassword = forgottenPassword;

      if (token) {
        const {
          data: { getUserByPasswordToken: user },
        } = await this.$apollo.query({
          query: GET_USER_BY_PASSWORD_TOKEN,
          variables: { token },
        });

        if (!user) {
          this.user = null;
          this.invalidToken = true;

          return;
        }

        this.user = user;
        lang = user.locale;
      }

      if (lang) {
        this.$i18n.locale = lang;
      }
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      async resetPassword() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        this.showAdminLoader(true);

        const {
          data: { resetPassword },
          errors,
        } = await this.$apollo.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            email: this.email.toLowerCase(),
          },
        });

        this.showAdminLoader(false);

        const type = resetPassword?.success ? 'success' : 'error';

        this.showNotification({ type, message: resetPassword?.message, errors });
        this.$v.$reset();
        this.email = '';
      },
      showNotification({ type, message, errors }) {
        this.message.error = '';
        this.message.success = '';
        const messageTransKey = (errors ? errors?.[0].message : message) || '';
        const params = messageTransKey.includes('rateLimited')
          ? { time: moment(errors?.[0]?.resetAt).locale(this.$i18n.locale).fromNow() }
          : null;
        this.message[type] = this.$te(messageTransKey) ? this.$t(messageTransKey, params) : '';
      },
      async changePassword() {
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        this.showAdminLoader(true);

        const {
          data: { changeForgottenPassword: login },
        } = await this.$apollo.mutate({
          mutation: CHANGE_FORGOTTEN_PASSWORD,
          variables: {
            token: this.token,
            password: this.password,
          },
        });

        this.showAdminLoader(false);

        if (login.success) {
          this.$store.dispatch('login', {
            expiration: login.expiration,
            accountType: login.accountType,
            locale: login.locale,
            impersonate: login.impersonate,
          });

          if (login.redirectUrl) {
            this.showAdminLoader(true);
            window.location = login.redirectUrl;

            return;
          }

          if (login.accountType === 'multiple') {
            this.$router.replace({ name: 'switch_account' });
          } else {
            this.$notify({
              type: 'success',
              text: this.$t('notifications.newPasswordSetAndLogin'),
            });
            await showInitialScreenByUserType(login.accountType);
          }
        } else {
          this.$notify({
            type: 'error',
            text: login.message,
          });
        }
      },
    },

    validations() {
      if (!this.token) {
        return {
          email: {
            required,
            isCoolEmail(v) {
              return email(v.toLowerCase());
            },
          },
        };
      }

      return {
        password: {
          required,
          minLength: minLength(8),
        },
        repeatPassword: {
          sameAsPassword: sameAs('password'),
        },
      };
    },
  };
</script>

<style lang="sass" scoped>
  #new-password-form
    text-align: center

    h1
      font-weight: 700 !important
      font-size: 1.375rem
      margin-bottom: 15px

    .your-email
      margin: 0 auto 2.1875rem
      .email
        font-weight: 700

    .brand-btn-primary
      width: auto
      font-size: 1.0625rem
      font-weight: 500
      margin: auto
      padding: 0.875rem 4.0625rem

    .invalid-token
      h1
        margin-bottom: 60px
</style>
